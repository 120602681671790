import React from "react"
import styled from '@emotion/styled'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #545454;
    height: 100%;

  `

  const Title = styled.h3`
    font-size: 1.5em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .75em;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .75em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      &:hover {
        color: #0C4E3F;
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `

  const VendorName = styled.h5`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  `
  const VendorCity = styled.p`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  `
  const VendorEmail = styled.a`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #f32b38;
  `
  const VendorDesc = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    margin-top: .75em;
    -webkit-font-smoothing: antialiased;
  `

  const Vendor = styled.div`
    width: 100%;
    padding: 1.5em;
    margin-bottom: 1em;
    border: 1px solid #dedede;

    @media (min-width: 1024px) {
      width: calc(50% - 2em);
      margin: 1em;
      padding: 2em;
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 2em);
    }
  `

  return (
        <Wrapper id="crafts">
          <InnerWrapper>
            <Left>
              <Title>Craft Vendors</Title>
            </Left>
            <Right>
              <Vendor>
                <VendorName>
                  2 Paws Doggy Treats
                </VendorName>
                <VendorCity>
                  Graceton, OH
                </VendorCity>
                <VendorEmail href="mailto:2paws@fairviewfm.org">2paws@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Tail-waggingly good treats made from healthy, human-grade ingrediants.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Appleton Soap Co.
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:appletonsoapco@fairviewfm.org">appletonsoapco@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Our mission is to make great bath and body products, not just great smelling products. Therefore, we use only the finest ingredients available, and make each and every product by hand.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Black Valley Silver
                </VendorName>
                <VendorCity>
                  Black Valley, WV
                </VendorCity>
                <VendorEmail href="mailto:blackvalleysilver@fairviewfm.org">blackvalleysilver@fairviewfm.org</VendorEmail>
                <VendorDesc>
                Our product is designed and handmade by us using vintage Silver-plated flatware - will take special orders using your heirloom silver!
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Creps Soap Mill
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:crepssoapmill@fairviewfm.org">crepssoapmill@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Ceramics by Michelle
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:ceramicsbymichelle@fairviewfm.org">ceramicsbymichelle@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Drink Up Juicery
                </VendorName>
                <VendorCity>
                  Gravley, OH
                </VendorCity>
                <VendorEmail href="mailto:drinkupjuicery@fairviewfm.org">drinkupjuicery@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Fresh pressed juice on the spot! Also offering delivery.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Friendly Spreads
                </VendorName>
                <VendorCity>
                  Portsmith, OH
                </VendorCity>
                <VendorEmail href="mailto:friendlyspreads@fairviewfm.org">friendlyspreads@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Online ordering and shipping for jams, spreads and gift certificates
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  It's All About the Bees
                </VendorName>
                <VendorCity>
                  Custer, OH
                </VendorCity>
                <VendorEmail href="mailto:allaboutbees@fairviewfm.org">allaboutbees@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Joans Art Pottery
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:joansartpottery@fairviewfm.org">joansartpottery@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Custom made, hand-thrown pottery.
                </VendorDesc>
              </Vendor>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
