import React from "react"
import styled from '@emotion/styled'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #545454;
    height: 100%;

  `

  const Title = styled.h3`
    font-size: 1.5em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .75em;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .75em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      &:hover {
        color: #0C4E3F;
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `

  const VendorName = styled.h5`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  `
  const VendorCity = styled.p`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  `
  const VendorEmail = styled.a`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #f32b38;
  `
  const VendorDesc = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    margin-top: .75em;
    -webkit-font-smoothing: antialiased;
  `

  const Vendor = styled.div`
    width: 100%;
    padding: 1.5em;
    margin-bottom: 1em;
    border: 1px solid #dedede;

    @media (min-width: 1024px) {
      width: calc(50% - 2em);
      margin: 1em;
      padding: 2em;
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 2em);
    }
  `

  return (
        <Wrapper id="bakedgoods">
          <InnerWrapper>
            <Left>
              <Title>Baked Goods Vendors</Title>
            </Left>
            <Right>
              <Vendor>
                <VendorName>
                  Amanda's Baked Creations
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:amandasbakedcreations@fairviewfm.org">amandasbakedcreations@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Cakes, cookies, doughnuts and other delicioius treats!
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  The Country Oven
                </VendorName>
                <VendorCity>
                  Graceton, OH
                </VendorCity>
                <VendorEmail href="mailto:countryoven@fairviewfm.org">countryoven@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Good Harvest Bread Co.
                </VendorName>
                <VendorCity>
                  Shadow Lake, OH
                </VendorCity>
                <VendorEmail href="mailto:goodharvestbreadco@fairviewfm.org">goodharvestbreadco@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Specializing in delicious whole grain breads.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Good to the Last Crumb
                </VendorName>
                <VendorCity>
                  Little Creek, OH
                </VendorCity>
                <VendorEmail href="mailto:goodtothelastcrumb@fairviewfm.org">goodtothelastcrumb@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  From delicious desserts to our beautiful wedding cakes, Good the Last Crumb is Fairview's #1 choice for baked goods.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Ken's Bakery
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:kensbakery@fairviewfm.org">kensbakery@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Authentic artisan French breads and pastries like no other.
                </VendorDesc>
              </Vendor>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
