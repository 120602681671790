import React from "react"
import styled from '@emotion/styled'
import Image from "./image.js"
import scrollTo from 'gatsby-plugin-smoothscroll';

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #545454;
    display: flex;
    flex-direction: row;
  `

  const Title = styled.h3`
    font-size: 2em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;

    @media (min-width: 1024px) {
      font-size: 2.5em;
      margin-bottom: .75em;
    }
  `

  const Subtitle = styled.div`
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-size: 1.15em;
    margin-bottom: 1.2em;
  `

  const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.12em;
      margin-bottom: 1.25em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }

    ul {
      display: list;
      margin-top: .5em;

      @media (min-width: 600px) {
        display: inline-flex;
      }

      li {
        margin-right: 1.5em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `
  const NavItem = styled.a`
    color: #f32b38;
    font-weight: 600;
    font-size: 1em;
    -webkit-font-smoothing: antialiased;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: #545454;
    }
  `

  const Right = styled.div`
    display: none;

    @media (min-width: 1024px) {
      display: block;
      margin-top: 2em;
    }
  `

  const ImageWrapper = styled.div`
    width: 425px;
    height: auto;
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <Left>
              <Title>Vendor Listings</Title>
              <Subtitle>Fairview Farmers Market vendors offer produce, herbs, plants, flowers, meats, baked goods, cheese, eggs, prepared foods, crafts, and additional specialty items.</Subtitle>
              <p>Each week Fairview Farmers Market holds three markets (Two during the winter months). Each market day typically sees between fifty to one hundred vendors. Saturday market days are usually the busiest and see the highest attendance.</p>
              <p>This vendor list is subject to change. Please contact individual vendors to see if they are currently active at the market.</p>
              <ul>
                <li><NavItem onClick={() => scrollTo('#produce')} alt="Produce">Produce</NavItem></li>
                <li><NavItem onClick={() => scrollTo('#meatdairy')} alt="Meat/Dairy Products">Meat/Dairy Products</NavItem></li>
                <li><NavItem onClick={() => scrollTo('#bakedgoods')} alt="Baked Goods">Baked Goods</NavItem></li>
                <li><NavItem onClick={() => scrollTo('#crafts')} alt="Crafts">Crafts</NavItem></li>
              </ul>
            </Left>
            <Right>
              <ImageWrapper>
                <Image />
              </ImageWrapper>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
