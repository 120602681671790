import React from "react"
import styled from '@emotion/styled'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #545454;
    height: 100%;

  `

  const Title = styled.h3`
    font-size: 1.5em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .75em;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .75em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      &:hover {
        color: #0C4E3F;
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `

  const VendorName = styled.h5`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  `
  const VendorCity = styled.p`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  `
  const VendorEmail = styled.a`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #f32b38;
  `
  const VendorDesc = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    margin-top: .75em;
    -webkit-font-smoothing: antialiased;
  `

  const Vendor = styled.div`
    width: 100%;
    padding: 1.5em;
    margin-bottom: 1em;
    border: 1px solid #dedede;

    @media (min-width: 1024px) {
      width: calc(50% - 2em);
      margin: 1em;
      padding: 2em;
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 2em);
    }
  `

  return (
        <Wrapper id="meatdairy">
          <InnerWrapper>
            <Left>
              <Title>Meat/Dairy Vendors</Title>
            </Left>
            <Right>
              <Vendor>
                <VendorName>
                  Bennington Butchers
                </VendorName>
                <VendorCity>
                  Bennington, OH
                </VendorCity>
                <VendorEmail href="mailto:benningtonbutchers@fairviewfm.org">benningtonbutchers@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Green Tree Farm
                </VendorName>
                <VendorCity>
                  Shadow Lake, OH
                </VendorCity>
                <VendorEmail href="mailto:greentreefarm@fairviewfm.org">greentreefarm@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Free range chicken meat and eggs.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Grier Farms
                </VendorName>
                <VendorCity>
                  Graceton, OH
                </VendorCity>
                <VendorEmail href="mailto:grierfarms@fairviewfm.org">grierfarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Cut-to-order beef, poultry, pork and lamb.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Happy Valley Beef
                </VendorName>
                <VendorCity>
                  Happy Valley, WV
                </VendorCity>
                <VendorEmail href="mailto:happyvalleybeef@fairviewfm.org">happyvalleybeef@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Premium grass-fed beef.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Jason's Jerky
                </VendorName>
                <VendorCity>
                  Gravley, OH
                </VendorCity>
                <VendorEmail href="mailto:jasonsjerkys@fairviewfm.org">jasonsjerkys@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Dozens of flavors of hand-made, nitrite-free jerkies.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Little Creek Farm
                </VendorName>
                <VendorCity>
                  Little Creek, OH
                </VendorCity>
                <VendorEmail href="mailto:littlecreekfarm@fairviewfm.org">littlecreekfarm@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Gourmet cheeses and handmade soap products.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Wolf Run Ranch
                </VendorName>
                <VendorCity>
                  Beaver, OH
                </VendorCity>
                <VendorEmail href="mailto:wolfrunranch@fairviewfm.org">wolfrunranch@fairviewfm.org</VendorEmail>
                <VendorDesc></VendorDesc>
              </Vendor>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
