import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/vendors/hero"
import Intro from "../components/vendors/intro"
import Produce from "../components/vendors/produce"
import Meat from "../components/vendors/meat"
import BakedGoods from "../components/vendors/bakedgoods"
import Crafts from "../components/vendors/crafts"


const Vendors = () => (
  <Layout>
    <SEO title="Vendors" />
    <Hero />
    <Intro />
    <Produce />
    <Meat />
    <BakedGoods />
    <Crafts />
  </Layout>
)

export default Vendors
