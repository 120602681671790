import React from "react"
import styled from '@emotion/styled'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #545454;
    height: 100%;

  `

  const Title = styled.h3`
    font-size: 1.5em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .75em;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .75em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      &:hover {
        color: #0C4E3F;
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `

  const VendorName = styled.h5`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  `
  const VendorCity = styled.p`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  `
  const VendorEmail = styled.a`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #f32b38;
  `
  const VendorDesc = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    margin-top: .75em;
    -webkit-font-smoothing: antialiased;
  `

  const Vendor = styled.div`
    width: 100%;
    padding: 1.5em;
    margin-bottom: 1em;
    border: 1px solid #dedede;

    @media (min-width: 1024px) {
      width: calc(50% - 2em);
      margin: 1em;
      padding: 2em;
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 2em);
    }
  `

  return (
        <Wrapper id="produce">
          <InnerWrapper>
            <Left>
              <Title>Produce Vendors</Title>
            </Left>
            <Right>
              <Vendor>
                <VendorName>
                  1939 Gardens
                </VendorName>
                <VendorCity>
                  Tamarack, OH
                </VendorCity>
                <VendorEmail href="mailto:1939gardens@fairviewfm.org">1939gardens@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  1939 Gardens has been doing business at FFM for over 16 years. We strive to grow tasty, quality vegetables and fruits.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  5 Star Produce
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:5starproduce@fairviewfm.org">5starproduce@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Anderson Gardens
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:andersongardens@fairviewfm.org">andersongardens@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Sweet corn, onions, garlic, zucchini and cucumbers.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  A&L Farms
                </VendorName>
                <VendorCity>
                  Gravley, OH
                </VendorCity>
                <VendorEmail href="mailto:alfarms@fairviewfm.org">alfarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Fresh from the farm produce. Delivery and CSA available.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Big Oak Farms
                </VendorName>
                <VendorCity>
                  Tamarack, OH
                </VendorCity>
                <VendorEmail href="mailto:bigoakfarms@fairviewfm.org">bigoakfarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Family owned and operated. Cabbage, bell peppers, zucchini, tomatoes and cucumbers.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Ed's Produce
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:edsproduce@fairviewfm.org">edsproduce@fairviewfm.org</VendorEmail>
                <VendorDesc></VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Farmer Frank's
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:farmerfranks@fairviewfm.org">farmerfranks@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Fresh and canned fruit and vegetables.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Grandview Farm
                </VendorName>
                <VendorCity>
                  Clymer, OH
                </VendorCity>
                <VendorEmail href="mailto:grandviewfarm@fairviewfm.org">grandviewfarm@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Beets, cabbage, carrots, cucumbers, leaf lettuce.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Greenleaf Farms
                </VendorName>
                <VendorCity>
                  Scribner, OH
                </VendorCity>
                <VendorEmail href="mailto:greenleaffarms@fairviewfm.org">greenleaffarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Hydroponic lettuce and other produce.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  In Season
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:inseason@fairviewfm.org">inseason@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Organic heirloom plants and produce. CSA available.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Kapper's Orchard
                </VendorName>
                <VendorCity>
                  Big Spoon, OH
                </VendorCity>
                <VendorEmail href="mailto:kappersorchard@fairviewfm.org">kappersorchard@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Apples, peaches, pears and other seasonal fruits. CSA available.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Old Camp Farms
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:oldcampfarms@fairviewfm.org">oldcampfarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  At Old Camp Farms, our passion is growing fresh, healthy, local produce using organic farming methods. Our two acres of vegetable produce vegetables and small fruit on land that has been in the family for three generations.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Shadow Lake Farms
                </VendorName>
                <VendorCity>
                  Shadow Lake, OH
                </VendorCity>
                <VendorEmail href="mailto:shadowlakefarms@fairviewfm.org">shadowlakefarms@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Stutzman Family Farm
                </VendorName>
                <VendorCity>
                  Graceton, OH
                </VendorCity>
                <VendorEmail href="mailto:stutzmanfamilyfarm@fairviewfm.org">stutzmanfamilyfarm@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  We bring you fresh from the farm organic and locally grown produce for your tables. Come and see what we have for you each week.
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Sunny Day Gardens
                </VendorName>
                <VendorCity>
                  Tamarack, OH
                </VendorCity>
                <VendorEmail href="mailto:sunnydaygardens@fairviewfm.org">sunnydaygardens@fairviewfm.org</VendorEmail>
                <VendorDesc>
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Timber Ridge
                </VendorName>
                <VendorCity>
                  Fairview, OH
                </VendorCity>
                <VendorEmail href="mailto:timberridge@fairviewfm.org">timberridge@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Timber Ridge grows a wide variety of fresh produce including blueberries, cabbage, carrots, cantaloupe, cucumbers, herbs, kale, lettuce, sweet/hot peppers, spinach, tomatoes and so much more!
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Twin Lakes Farm
                </VendorName>
                <VendorCity>
                  Twin Lakes, OH
                </VendorCity>
                <VendorEmail href="mailto:twinlakesfarm@fairviewfm.org">twinlakesfarm@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Growing produce for over 50 years. If you miss us at the market, stop by our farm store which is open daily. Nothing beats Fresh from the Farm!
                </VendorDesc>
              </Vendor>
              <Vendor>
                <VendorName>
                  Wild Acres
                </VendorName>
                <VendorCity>
                  Graceton, OH
                </VendorCity>
                <VendorEmail href="mailto:wildacres@fairviewfm.org">wildacres@fairviewfm.org</VendorEmail>
                <VendorDesc>
                  Sustainable small farm raising all natural herbs, vegetables, rhubarb, and heritage breeds of chickens for meat & egg production. We sell fresh from our farm to you!
                </VendorDesc>
              </Vendor>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
